import React from 'react';
import './App.css';
import successKid from './success-kid.png';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { FaTwitter, FaTelegramPlane } from 'react-icons/fa';


function Navbar() {
  return (
    <nav className="bg-gray-900 px-4 py-3 shadow-md">
      <ul className="flex justify-center space-x-4">
        <li>
          <Link to="/" className="text-yellow-400 hover:text-yellow-600">
            Home
          </Link>
        </li>
        <li>
          <Link to="/presale" className="text-yellow-400 hover:text-yellow-600">
            Presale
          </Link>
        </li>
      </ul>
    </nav>
  );
}

function HomePage() {
  const tokenDetails = {
    protocol: 'BRC-20',
    operation: 'deploy',
    ticker: '$ROI',
    maxSupply: '10,000,000',
    limit: '10,000,000',
  };

  return (
    <div className="bg-gradient-to-br from-yellow-500 to-orange-500 min-h-screen font-sans text-white">
      <div className="container mx-auto py-12 px-4">
        <div className="flex flex-col items-center mb-12">
          <img
            src={successKid}
            alt="Success Kid"
            className="w-32 h-32 mb-4 animate-pulse"
          />
          <h1 className="text-6xl font-extrabold tracking-wide font-serif">
            {tokenDetails.ticker}
          </h1>
          <p className="text-2xl mt-4 text-center">
            Ride the wave of memecoin success with {tokenDetails.ticker}. It's time to dominate the BRC-20 landscape and create unmatched ROI for everyone.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Token Details */}
          <div className="bg-gray-900 p-8 rounded-lg shadow-md">
            <h2 className="text-3xl font-bold mb-6">Token Specifications</h2>
            <p>Protocol: {tokenDetails.protocol}</p>
            <p>Operation: {tokenDetails.operation}</p>
            <p>Ticker: {tokenDetails.ticker}</p>
            <p>Max Supply: {tokenDetails.maxSupply}</p>
            <p>Limit: {tokenDetails.limit}</p>
            <p>
              Inscription:{" "}
              <a
                href="https://ordinals.com/inscription/055612b2ab9f64fae351f8e31bc03937e6f7ef5d3bb86e2496ca0365d509ea0bi0"
                target="_blank"
                rel="noopener noreferrer"
                className="text-yellow-400 hover:text-yellow-600"
              >
                See Inscription
              </a>
            </p>
          </div>

         {/* Social Media */}
         <div className="bg-gray-900 p-8 rounded-lg shadow-md">
            <h2 className="text-3xl font-bold mb-6">Connect with Us</h2>
            <ul className="space-y-2">
              <li>
                <a
                  href="https://twitter.com/roibrc20"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-yellow-400 hover:text-yellow-600 flex items-center"
                >
                  <FaTwitter className="mr-2" /> Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/roibrc20"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-yellow-400 hover:text-yellow-600 flex items-center"
                >
                  <FaTelegramPlane className="mr-2" /> Telegram
                </a>
              </li>
            </ul>
          </div>

          {/* Tokenomics */}
          <div className="bg-gray-900 p-8 rounded-lg shadow-md">
            <h2 className="text-3xl font-bold mb-6">Token Distribution</h2>
            <p>Initial Supply: 10,000,000 {tokenDetails.ticker}</p>
            <p>Presale: 50%</p>
            <p>Team: 10%</p>
            <p>Community Airdrops: 5%</p>
            <p>Individual Airdrops: 5%</p>
            <p>Future Plans: 30%</p>
          </div>
        </div>

        {/* Invitation */}
        <div className="mt-12 bg-gray-900 p-8 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold mb-4">{tokenDetails.ticker} Airdrop</h2>
          <p>
            Join the movement as we dominate the BRC-20 landscape and create substantial ROI for all. No matter if you're a developer, marketer, or just an aficionado, we're inviting you to be a part of the {tokenDetails.ticker} journey towards greatness. Check if you're eligible for our exclusive airdrop and become an integral member of our thriving community.
          </p>
          <div className="mt-4">
            <a
              href="https://docs.google.com/spreadsheets/d/e/2PACX-1vS-3q8w08xRlxzJ459OE8uwdWPi8aQ_er_6IuHy1kB6lKJCdyv9hkeGZeqF7wTgh49xJqUuFlQ89_R9/pubhtml"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-yellow-400 hover:bg-yellow-600 text-gray-800 px-6 py-2 rounded-lg font-bold"
            >
              See if you qualify for Airdrop
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function Presale() {
  return (
    <div className="bg-gradient-to-br from-yellow-500 to-orange-500 min-h-screen font-sans text-white">
      <div className="container mx-auto py-12 px-4">
        <h1 className="text-4xl font-bold mb-6">$ROI BRC-20 Presale</h1>

        <div className="flex flex-col items-center mb-12">
          <img
            src={successKid}
            alt="Success Kid"
            className="w-32 h-32 mb-4 animate-pulse"
          />
        </div>

        <div className="bg-gray-900 p-8 rounded-lg shadow-md mb-8">
          <h2 className="text-3xl font-bold mb-6">Presale Address</h2>
          <p>Presale has ended.
          </p>
        </div>

        <div className="bg-gray-900 p-8 rounded-lg shadow-md mb-8">
          <h2 className="text-3xl font-bold mb-6">Presale Details</h2>
          <ul className="list-disc list-inside space-y-2">
            <li>Hardcap: 1.5 Bitcoin</li>
            <li>Maximum Buy-in: 0.1 Bitcoin</li>
            <li>Minimum Buy-in: 0.005 Bitcoin</li>
            <li>
              The presale will end after 24 hours or once the hardcap has been reached, whichever comes first.
            </li>
            <li>
             Token Distribution can be viewed on our homepage.
            </li>
            <li>Both Presale and Airdrop tokens will be distributed after the presale has concluded.</li>
            <li>
              To participate in the presale, use a wallet that is compatible with BRC-20. Tokens will be sent to the same address that participated in the presale. Both Taproot and SegWit work for receiving the $ROI after the presale. Use an Ordinals/BRC-20 compatible wallet. We recommend using a completely decentralized wallet, such as Sparrow Wallet or ORD, not linked to CEXs. However, Unisat and Xverse wallets will both work.
            </li>
            <li>
              Before investing, make sure to check that the hardcap has not been reached. Visit{' '}
              <a
                href="https://mempool.space/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-yellow-400 hover:text-yellow-600"
              >
                mempool.space
              </a>{' '}
              and check the wallet balance. Our hardcap is 1.5 BTC; once that amount has been reached or 24 hours have passed since the start of the presale, you won't be accepted for the presale and your funds will be returned.
            </li>
      <li>
  Be sure to follow our Telegram and Twitter for the latest updates.
  <a
    href="https://twitter.com/roibrc20"
    target="_blank"
    rel="noopener noreferrer"
    className="text-yellow-400 hover:text-yellow-600 flex items-center ml-1"
  >
    <FaTwitter className="mr-2" /> @roibrc20
  </a>
  <a
    href="https://t.me/roibrc20"
    target="_blank"
    rel="noopener noreferrer"
    className="text-yellow-400 hover:text-yellow-600 flex items-center ml-1"
  >
    <FaTelegramPlane className="mr-2" /> t.me/roibrc20
  </a>
</li>

</ul>
</div>
</div>
</div>

);
}




function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/presale" element={<Presale />} />
      </Routes>
    </Router>
  );
}



export default App;

